import React from "react";

const Content1 = () => {
    return (
        <div className="content-section-01">
            <div className="container">
                <div className="row">
                    <div className="col-lg-5 col-sm-6 col-md-5 col-xl-5">
                        <div className="content-left-image">
                            <img src="image/tracking_phone.png" alt="" />
                        </div>
                    </div>

                    <div className="col-lg-7 col-sm-6 col-md-7 col-xl-6 offset-xl-1">
                        <div className="content-right-content">
                            <h5>Awesome Tech!</h5>
                            <h2>Track your clothes every step of the way</h2>
                            <p>The SoapLabs app makes it easy to sign up, and schedule your first pickup. The app also offers you peace of mind. Track your bag from the time it leaves your doorstep to the time it gets back.</p>
                            <div className="content-btn"><a href="https://soap-labs.herokuapp.com/signup" className="btn btn--white">Get Started</a></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Content1;
