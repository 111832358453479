import React from 'react'

const Clients = () => {
    return (
        <section className="client-section">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xl-6 col-lg-7 col-md-8 col-sm-11">
                    <div className="hero-content">
                            <h5>Get In Touch</h5>
                            <h2>Contact Us</h2>
                            <p>Have an issue with an order? See a bug in our systems? Submit your message below.</p>
                        </div>
                    </div>
                </div>
                <br/>
                <br/>
                <form className="form">
                    <div className="form-group">
                        <input type="text" className="form-control" placeholder="Enter your name" />
                    </div>
                    <div className="form-group">
                        <input type="text" className="form-control" placeholder="Enter your email" />
                    </div>
                    <div className="form-group">
                        <textarea placeholder="Enter your message" className="form-control"></textarea>
                    </div>
                    <div className="form-group">
                        <button placeholder="Enter your message" className="btn btn-block btn-primary btn--hover-shine">Send Message</button>
                    </div>
                </form>
            </div>
        </section>
    );
}

export default Clients