import React from 'react'

const Clients = () => {
    return (
        <section className="pricing-section section-spacing">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xl-6 col-lg-7 col-md-8 col-sm-11">
                        <div className="section-title pb-lg--85">
                            <span className="title-sm-text">Pricing</span>
                            <h2>Ready to get started? Start a new order today!</h2>
                        </div>
                    </div>
                </div>

                <div className="row mb-d-30">
                    <div className="col-md-4 mb--30">
                        <div className="pricing-card  active">

                            <div className="card-head">
                                <h6>SoapLabs Standard</h6>
                                <h2><span className="small-title">$</span><span className="big-txt">2.00</span><span className="month">/pound</span>
                                </h2>
                                <p>Good for one off orders</p>
                            </div>

                            <div className="card-content">
                                <ul className="pricing-list">
                                    <li><span><i className="icon icon-check-2"></i></span>Free Pickup</li>
                                    <li><span><i className="icon icon-check-2"></i></span>Free Delivery</li>
                                    <li className="disable-opt"><span><i className="icon icon-check-2"></i></span>Plus Quality Soap</li>
                                    <li className="disable-opt"><span><i className="icon icon-check-2"></i></span>Plus Quality Dryer Sheets</li>
                                </ul>
                                <div className="card-btn">
                                    <a href="https://soap-labs.herokuapp.com/signup" className="btn btn--primary btn--hover-shine">Get Started</a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 mb--30">
                        <div className="pricing-card">

                            <div className="card-head">
                                <h6>SoapLabs Plus</h6>
                                <span className="card-badge">Popular</span>
                                <h2><span className="small-title">$</span><span className="big-txt">1.75</span><span className="month">/pound</span>
                                </h2>
                                <p>Good for individuals and couples</p>
                            </div>


                            <div className="card-content">
                                <ul className="pricing-list">
                                    <li><span><i className="icon icon-check-2"></i></span>Free Pickup</li>
                                    <li><span><i className="icon icon-check-2"></i></span>Free Delivery</li>
                                    <li><span><i className="icon icon-check-2"></i></span>Plus Quality Soap</li>
                                    <li><span><i className="icon icon-check-2"></i></span>Plus Quality Dryer Sheets</li>
                                </ul>
                                <div className="card-btn">
                                    <a href="https://soap-labs.herokuapp.com/signup" className="btn btn--primary btn--hover-shine">Get Started</a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 mb--30">
                        <div className="pricing-card">

                            <div className="card-head">
                                <h6>SoapLabs Premium</h6>
                                <h2><span className="small-title">$</span><span className="big-txt">1.85</span><span className="month">/pound</span>
                                </h2>
                                <p>Good for growing families</p>
                            </div>


                            <div className="card-content">
                                <ul className="pricing-list">
                                    <li><span><i className="icon icon-check-2"></i></span>Free Pickup</li>
                                    <li><span><i className="icon icon-check-2"></i></span>Free Delivery</li>
                                    <li><span><i className="icon icon-check-2"></i></span>Plus Quality Soap</li>
                                    <li><span><i className="icon icon-check-2"></i></span>Plus Quality Dryer Sheets</li>
                                </ul>
                                <div className="card-btn">
                                    <a href="https://soap-labs.herokuapp.com/signup" className="btn btn--primary btn--hover-shine">Get Started</a>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>


    );
}

export default Clients