import React from 'react';
import './App.css';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import Header from './sections/Header'
import Hero from './sections/Hero'
import Feature from './sections/Feature'
import Step1 from './sections/Step1'
import Step2 from './sections/Step2'
import Process from './sections/Process'
import Contact from './sections/Contact'
import Pricing from './sections/Pricing'
import Footer from './sections/Footer'
import Terms from './sections/Terms'
import Privacy from './sections/Privacy'

function App() {
  return (
    <>
      <Router>
        <Header />
        <Switch>
          <Route path="/" exact>
            <Hero />
            <Feature />
            <Step1 />
            <Step2 />
            <Pricing />
          </Route>
          <Route path="/services" exact>
            <Process />
          </Route>
          <Route path="/pricing" exact>
            <Pricing />
          </Route>
          <Route path="/contact" exact>
            <Contact />
          </Route>
          <Route path="/terms" exact>
            <Terms />
          </Route>
          <Route path="/privacy" exact>
            <Privacy />
          </Route>
        </Switch>
        <Footer />
      </Router>
    </>
  );
}

export default App;
