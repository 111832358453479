import React from "react";

const Footer = () => {
    return (
        <div className="footer-section">
            <div className="container">
                <div className="row margin-decrese">
                    <div className="col-xl-4 col-lg-5 col-margin">
                        <div className="single-footer">
                            <div className="footer-title">
                                <div className="brand-logo">
                                    <img src="./image/soaplabs_black.png" alt="" />
                                </div>
                            </div>
                            <div className="footer-text">
                                <p>Soap Labs Laundry is committed to fast, and quality laundry service for you.</p>
                            </div>
                            <ul className="footer-social-list">
                                <li>
                                    <a href="">
                                        <i className="icon icon-logo-fb-simple"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="">
                                        <i className="icon icon-logo-twitter"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="">
                                        <i className="icon icon-google"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-2 col-sm-4 col-6 offset-xl-1 col-margin">

                    </div>
                    <div className="col-lg-2 col-xl-3 col-sm-4 col-6 col-margin">

                    </div>
                    <div className="col-lg-2 col-sm-4 col-margin">
                        <div className="single-footer">
                            <div className="footer-title">
                                <h6>Support</h6>
                            </div>
                            <ul className="footer-list">
                                <li><a href="/contact">Contact</a></li>
                                <li><a href="/contact">Help & Support</a></li>
                                <li><a href="/privacy">Privacy Policy</a></li>
                                <li><a href="/terms">Terms & Conditions</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;
