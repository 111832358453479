import React from 'react';

const Header = () => {
    return (
        <header className="site-header">
            <nav className="navbar navbar-expand-lg navbar-light">
                <div className="brand-logo">
                    <a className="navbar-brand" href="/">
                        <img width="200" className="img-fluid" src="image/soaplabs_black.png" alt="" />
                    </a>
                </div>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav mr-auto">
                        <li className="menu-item nav-item"><a className="nav-link" href="/">Home</a></li>
                        <li className="menu-item nav-item"><a className="nav-link" href="/services">Process</a></li>
                        <li className="menu-item nav-item"><a className="nav-link" href="/pricing">Pricing</a></li>
                        <li className="menu-item nav-item"><a className="nav-link" href="/contact">Contact</a></li>
                    </ul>
                    <form className="form-inline my-2 my-lg-0">
                        <div className="header-btns justify-content-end">
                            <a href="https://soap-labs.herokuapp.com/login" className="btn btn-outlined--primary btn-sm mr--10">Login</a>
                            <a href="https://soap-labs.herokuapp.com/signup" className="btn btn-primary btn-sm">Signup</a>
                        </div>
                    </form>
                </div>
            </nav>
        </header>

        /*
        <header className="site-header">
            <div className="container">
                <div className="row justify-content-center align-items-center position-relative">
                    <div className="col-sm-3 col-6 col-lg-2 col-xl-2 order-lg-1">
                        <div className="brand-logo">
                            <a href="/"><img src="image/soaplabs_black.png" alt="" /></a>
                        </div>
                    </div>
                    <div className="col-sm-8 col-lg-3 col-xl-3 d-none d-sm-block order-lg-3 offset-xl-1">
                        <div className="header-btns justify-content-end">
                            <a href="/login" className="btn btn-outlined--primary btn-sm mr--10">Login</a>
                            <a href="https://soap-labs.herokuapp.com/signup" className="btn btn-primary btn-sm">Signup</a>
                        </div>
                    </div>
                    <div className="col-sm-1 col-6 col-lg-7 col-xl-6  position-static order-lg-2">
                        <div className="main-navigation">
                            <ul className="main-menu">
                                <li className="menu-item"><a href="/">Home</a></li>
                                <li className="menu-item"><a href="/services">Process</a></li>
                                <li className="menu-item"><a href="/pricing">Pricing</a></li>
                                <li className="menu-item"><a href="/contact">Contact</a></li>
                            </ul>
                        </div>
                        <div className="mobile-menu"></div>
                    </div>
                </div>
            </div>
            <br />
        </header>
    */
    );
}

export default Header;