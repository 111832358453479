import React from "react";

const Content2 = () => {
    return (
        <div className="content-section-02">
            <div className="container">
                <div className="content-wrapper">
                    <div className="row ">
                        <div className="col-lg-7 col-md-7 col-xl-6 order-2 order-md-1">
                            <div className="content-left-content">
                                <h5>Better People!</h5>
                                <h2>Let the pros handle your clothes</h2>
                                <p>Our team of talented SoapLabs Soap Scientists have been doing laundry for years! Everyone on the SoapLabs team is trustworthy, competent, and professional, ensuring your clothes are properly washed, dried, folded and returned, every time you schedule a job.</p>
                                <br/>
                                <div className="content-btn"><a href="https://soap-labs.herokuapp.com/signup" className="btn btn-primary btn-lg">Sign Up Today</a></div>
                            </div>
                            {/*
                            <div className="row justify-content-between">
                                <div className="col-lg-6 col-sm-6">

                                    <div className="feature-widget icon-top-round">
                                        <div className="widget-icon">
                                            <i className="icon icon-edit-72"></i>
                                        </div>

                                        <div className="content">
                                            <h5>Easy to use</h5>
                                            <p>Event is not like most tech conferences. We want our design to engage.</p>
                                        </div>
                                    </div>

                                </div>

                                <div className="col-lg-6 col-sm-6">
                                    <div className="feature-widget icon-top-round">
                                        <div className="widget-icon icon-round">
                                            <i className="icon icon-webpage"></i>
                                        </div>

                                        <div className="content">
                                            <h5>Beautiful Sections</h5>
                                            <p>Event is not like most tech conferences. We want our design to engage.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            */}
                        </div>

                        <div className="col-lg-5 col-md-5 col-xl-4 offset-xl-2 order-1 order-md-2">
                            <div className="content-right-image">
                                <img src="image/washer.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Content2;
