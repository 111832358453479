import React from "react";
import { rosybrown } from "color-name";

const Hero = () => {

    return (
        <div className="hero-area">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-8 col-xl-7">
                        <div className="hero-content">
                            <h5>SoapLabs Laundry</h5>
                            <h2>Your laundry, done for you.</h2>
                            <p>Nobody likes laundry. Get your laundry picked up, cleaned, folded, and returned to your door, fast!</p>
                        </div>
                    </div>

                    <div className="col-md-8">
                        <div className="subscription-form">
                            <form>
                                <div className="form-group">
                                    <a href="https://soap-labs.herokuapp.com/signup" className="btn btn-primary btn-lg btn--hover-shine">Sign Me Up</a>

                                    {/*
                                    <input type="text" className="form-control" placeholder="Enter your email" />
                                    <button className="submit-btn btn--hover-shine">Sign Me Up</button>
                                    */}
                                </div>
                            </form>
                            {/*
                            <p className="form-text">Or Sign up with your social profile</p>
                            <br />
                            <div className="row">
                                <div class="col-md-6">
                                    <a className="btn btn-primary btn-block" href="">
                                        <div className="row">
                                            <div className="col-10">
                                                Signup with Facebook
                                        </div>
                                            <div className="col-2">
                                                <i class="icon icon-logo-fb-simple img-fluid"></i>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div class="col-md-6">
                                    <a className="btn btn-warning btn-block" href="">
                                        <div className="row">
                                            <div className="col-10">
                                                Signup with Google
                                        </div>
                                            <div className="col-2">
                                                <i class="icon icon-google img-fluid"></i>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            */}
                        </div>
                    </div>
                </div>
                <br />
                <br />
                <br />
            </div>
        </div>
    );
}

export default Hero;
