import React from "react";

const Hero = () => {
    return (
        <div class="feature-area">
            <div class="container">
                <div class="row mb-d-30">
                    <div class="col-lg-4 col-sm-6 mb--30">
                        <div class="feature-widget">
                            <div class="widget-icon">
                                <h1>1</h1>
                            </div>
                            <div class="content">
                                <h5>Create Your Account</h5>
                                <p>Sign up for a free SoapLabs account in just a few clicks.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 mb--30">
                        <div class="feature-widget">
                            <div class="widget-icon">
                                <h1>2</h1>
                            </div>
                            <div class="content">
                                <h5>Book your Laundry Pickup</h5>
                                <p>Schedule the date and time of your pickup.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 mb--30">
                        <div class="feature-widget">
                            <div class="widget-icon">
                                <h1>3</h1>
                            </div>
                            <div class="content">
                                <h5>Sit back and relax!</h5>
                                <p>SoapLabs will wash, dry, and fold your laundry so you don't have to.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <br/>
                <div className="row">
                    <div className="col-md-2"></div>
                    <div className="col-md-8 text-center">
                        <a href="https://soap-labs.herokuapp.com/signup" className="btn btn-primary">Book Your First Job</a>
                    </div>
                    <div className="col-md-2"></div>
                </div>
            </div>
        </div>
    );
}

export default Hero;
