import React from "react";

const Content1 = () => {
    return (
        <div className="content-section-01">
            <div className="container">
                <div className="row">
                    <div className="col-lg-5 col-sm-6 col-md-5 col-xl-5">
                        <div className="content-left-image">
                            <br />
                            <br />
                            <br />
                            <br />
                            <img className="img-fluid" src="image/create.gif" alt="" />
                        </div>
                    </div>

                    <div className="col-lg-7 col-sm-6 col-md-7 col-xl-6 offset-xl-1">
                        <div className="content-right-content">
                            <h5>Step 1</h5>
                            <h2>Create your account.</h2>
                            <p>Sign up for a free SoapLabs account in just a few clicks. Once you've signed up we'll send you a SoapLabs welcome kit including a patent pending SoapLabs laundry bag.</p>
                            <div className="content-btn"><a href="https://soap-labs.herokuapp.com/signup" className="btn btn--white">Sign Up</a></div>
                        </div>
                    </div>
                </div>

                <br />
                <br />

                <div className="row">
                    <div className="col-lg-7 col-sm-6 col-md-7 col-xl-6 offset-xl-1">
                        <div className="content-right-content">
                            <h5>Step 2</h5>
                            <h2>Book your laundry pickup.</h2>
                            <p>Schedule the date and time of your pickup, place your clothes on your doorstep, and get on with your day!</p>
                            <div className="content-btn"><a href="https://soap-labs.herokuapp.com/signup" className="btn btn--white">Get Booking</a></div>
                        </div>
                    </div>

                    <div className="col-lg-5 col-sm-6 col-md-5 col-xl-5">
                        <div className="content-left-image">
                            <br />
                            <br />
                            <br />
                            <br />
                            <img className="img-fluid" src="image/pickup.gif" alt="pickup" />
                        </div>
                    </div>
                </div>

                <br />

                <div className="row">
                    <div className="col-lg-5 col-sm-6 col-md-5 col-xl-5">
                        <div className="content-left-image">
                            <br />
                            <br />
                            <br />
                            <br />
                            <img src="image/relax.gif" alt="" />
                        </div>
                    </div>

                    <div className="col-lg-7 col-sm-6 col-md-7 col-xl-6 offset-xl-1">
                        <div className="content-right-content">
                            <h5>Step 3</h5>
                            <h2>Sit back and relax!</h2>
                            <p>Do that thing you've always wanted to do with the time you now have freed up! SoapLabs will wash, dry, and fold your laundry so you don't have to.</p>
                            <div className="content-btn"><a href="#" className="btn btn--white">Free Your Schedule</a></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Content1;
